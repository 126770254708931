export const environment = {
    production: false,
    API_RETURNS: 'https://order-returns-dev.cc.cloudintercorpretail.pe',
    API_FILES: 'https://files-dev.cc.cloudintercorpretail.pe',
    API_STORE: 'https://store-prd.cc.cloudintercorpretail.pe',
    API_TRANSACTIONS: 'https://transaction-dev.cc.cloudintercorpretail.pe',
    API_INCIDENCE: 'https://tracking-incidence-dev.cc.cloudintercorpretail.pe/v2',
    API_CHANGES_RETURNS: 'https://order-returns-dev.cc.cloudintercorpretail.pe',
    API_RULES: 'https://returns-rules-dev.cc.cloudintercorpretail.pe'
}
