import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class UtilService {

	recieveTenantId = new Subject<string>();
	recieveCodeStore = new Subject<string>();
	recieveTrackingGroup = new Subject<string>();

	setTenantId(tenantId: string) {
		this.recieveTenantId.next(tenantId);
	}

	setStoreCode(storeCode: string) {
		this.recieveCodeStore.next(storeCode);
	}

	setTrackingGroup(trackingGroup: string) {
		this.recieveTrackingGroup.next(trackingGroup);
	}
}
