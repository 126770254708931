import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { UtilService } from './shared/util.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	tenantId: string;

	constructor(
		private utilService: UtilService,
		private titleService: Title,
		@Inject(DOCUMENT) private _document: Document,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) { 
		this.matIconRegistry.addSvgIcon(
			'shipment_1',
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icon/shipment_1.svg")
		  );
	}

	ngOnInit() {
		this.utilService.recieveTenantId.subscribe(tenantId => {
			var appFavicon = this._document.getElementById('appFavicon') as HTMLVideoElement;
			if (tenantId.toUpperCase() === 'RPLAZA') {
				this.titleService.setTitle("Real Plaza");
				appFavicon.setAttribute('href', 'assets/img/rplaza_favicon_16x16.ico');
			} else if (tenantId.toUpperCase() === 'HPSA') {
				this.titleService.setTitle("Promart");
				appFavicon.setAttribute('href', 'assets/img/hpsa_favicon_16x16.ico');
			} else if (tenantId.toUpperCase() === 'TPSA') {
				this.titleService.setTitle("Oechsle");
				appFavicon.setAttribute('href', 'assets/img/tpsa_favicon_16x16.ico');
			} else if (tenantId.toUpperCase() === 'PVEA') {
				this.titleService.setTitle("plazaVea");
				appFavicon.setAttribute('href', 'assets/img/pvea_favicon_16x16.ico');
			} else if (tenantId.toUpperCase() === 'AGORA') {
				this.titleService.setTitle("Agora");
				appFavicon.setAttribute('href', 'assets/img/agora_favicon_16x16.ico');
			}
		});
	}
}
